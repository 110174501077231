import { get, remove, update } from './https'

export const getNotificationByShop = async (shopId) => {
  if (shopId) {
    const path = `notification/${shopId}`

    const result = await get(path)
    return result.data.data
  }
}

export const updateReadNotification = async (id) => {
  const result = await update(`notification/${id}`)
  return result.data
}

export const deleteNotification = async (id) => {
  const result = await remove(`/notification/${id}`)
  return result.data
}
