import { ROLE } from 'apps/constants'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'store/userSlice/userSelector'
import Forbiden from '../Forbiden'
import styled from 'styled-components'

const Wrapper = styled.div`
  min-height: calc(100vh - 320px);

  display: flex;
  justify-content: center;
  align-items: center;
`
const RoleBaseRouteSeller = ({ children }) => {
  const currentUser = useSelector(selectCurrentUser)
  console.log('currentUser', currentUser)

  if (currentUser?.role === ROLE.SELLER) {
    return <>{children}</>
  } else {
    return (
      <Wrapper>
        <Forbiden />
      </Wrapper>
    )
  }
}

export default RoleBaseRouteSeller
