import Footer from 'apps/components/organisms/Footer'
import Header from 'apps/components/organisms/Header'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { StyledBackTop } from './styled'

const LayoutBase = () => {
  return (
    <>
      <Header />
      <Outlet />
      <ToastContainer autoClose={2000} />

      <StyledBackTop />
      <Footer />
    </>
  )
}

export default LayoutBase
