// Libraries
import {
  Link,
  BrowserRouter as Router,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Result } from 'antd'
import { useEffect, useState } from 'react'
import ReactGA4 from 'react-ga4'
import { useSelector } from 'react-redux'
// Organnisms

import Home from './apps/modules/Dashboard/Home/loadable'
// Modules
import LoadingPage from 'apps/components/molecules/LoadingPage'
import LayoutBase from 'apps/components/templates/LayoutBase'
import ProtectedUser from 'apps/components/common/ProtectedUser'
import ProtectedSeller from 'apps/components/common/ProtectedSeller'

import Login from 'apps/modules/Auth/Login/loadable'
import ProductDetail from 'apps/modules/Dashboard/ProductDetail/loadabel'
import ProductCategory from 'apps/modules/Dashboard/ProductCategory/loadable'
import PurchaseHistory from 'apps/modules/Dashboard/Purchase_History/loadable'
import Cart from 'apps/modules/Cart/loadable'
import SearchPage from 'apps/modules/Dashboard/SearchPage/loadable'
import RegisterSeller from 'apps/modules/Dashboard/Register_Seller/loadabel'
import ResetPassword from 'apps/modules/Auth/ResetPassword/loadable'
import ResultPayment from 'apps/modules/Dashboard/ResultPayment/loadable'
import Profile from 'apps/modules/Dashboard/Profile/loadable'
import SellersPage from 'apps/modules/Dashboard/SellersPage/loadable'
import Register from 'apps/modules/Auth/Register/loadable'
import { selectCurrentUser } from 'store/userSlice/userSelector'

// Turn off fresh data onfocus
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 5 * 60,
    },
  },
})

ReactGA4.initialize('G-N9BH75N5H4') // Replace 'YOUR_TRACKING_ID' with your actual tracking ID

export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement('script')
  script.src = urlOfTheLibrary
  script.async = true
  document.body.appendChild(script)
}

function App() {
  const [showApp, setShowApp] = useState(false)

  const currentUser = useSelector(selectCurrentUser)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowApp(true)
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  useEffect(() => {
    ReactGA4.initialize('G-N9BH75N5H4', {
      gaOptions: {
        userId: currentUser ? currentUser._id : 'n/a',
      },
    })

    ReactGA4.send({
      hitType: 'pageview',
      page: document.location.pathname,
      title: document.title,
    })
  }, [currentUser])

  const router = createBrowserRouter([
    {
      path: '/',
      element: <LayoutBase />,
      errorElement: (
        <div className="w-full min-h-screen flex justify-center items-center">
          <Result
            status="404"
            title="Không tìm thấy trang"
            extra={
              <Link type="primary" to="/">
                Quay về trang chủ
              </Link>
            }
          />
        </div>
      ),
      children: [
        {
          index: true,
          element: <Home />,
        },
      ],
    },
    {
      path: '/login',
      element: <LayoutBase />,
      children: [
        {
          index: true,
          element: <Login />,
        },
      ],
      errorElement: (
        <div className="w-full min-h-screen flex justify-center items-center">
          <Result
            status="404"
            title="Không tìm thấy trang"
            extra={
              <Link type="primary" to="/">
                Quay về trang chủ
              </Link>
            }
          />
        </div>
      ),
    },
    {
      path: '/register',
      element: <LayoutBase />,
      children: [
        {
          index: true,
          element: <Register />,
        },
      ],

      errorElement: (
        <div className="w-full min-h-screen flex justify-center items-center">
          <Result
            status="404"
            title="Không tìm thấy trang"
            extra={
              <Link type="primary" to="/">
                Quay về trang chủ
              </Link>
            }
          />
        </div>
      ),
    },
    {
      path: '/product/:id',
      element: <LayoutBase />,
      children: [
        {
          index: true,
          element: <ProductDetail />,
        },
      ],
      errorElement: (
        <div className="w-full min-h-screen flex justify-center items-center">
          <Result
            status="404"
            title="Không tìm thấy trang"
            extra={
              <Link type="primary" to="/">
                Quay về trang chủ
              </Link>
            }
          />
        </div>
      ),
    },
    {
      path: '/productsub/:id',
      element: <LayoutBase />,
      children: [
        {
          index: true,
          element: <ProductCategory />,
        },
      ],
      errorElement: (
        <div className="w-full min-h-screen flex justify-center items-center">
          <Result
            status="404"
            title="Không tìm thấy trang"
            extra={
              <Link type="primary" to="/">
                Quay về trang chủ
              </Link>
            }
          />
        </div>
      ),
    },
    {
      path: '/purchase-history',
      element: <LayoutBase />,
      children: [
        {
          index: true,
          element: (
            <ProtectedUser>
              <PurchaseHistory />
            </ProtectedUser>
          ),
        },
      ],
      errorElement: (
        <div className="w-full min-h-screen flex justify-center items-center">
          <Result
            status="404"
            title="Không tìm thấy trang"
            extra={
              <Link type="primary" to="/">
                Quay về trang chủ
              </Link>
            }
          />
        </div>
      ),
    },
    {
      path: '/cart',
      element: <LayoutBase />,
      children: [
        {
          index: true,
          element: <Cart />,
        },
      ],
      errorElement: (
        <div className="w-full min-h-screen flex justify-center items-center">
          <Result
            status="404"
            title="Không tìm thấy trang"
            extra={
              <Link type="primary" to="/">
                Quay về trang chủ
              </Link>
            }
          />
        </div>
      ),
    },
    {
      path: '/searchpage',
      element: <LayoutBase />,
      children: [
        {
          index: true,
          element: <SearchPage />,
        },
      ],
      errorElement: (
        <div className="w-full min-h-screen flex justify-center items-center">
          <Result
            status="404"
            title="Không tìm thấy trang"
            extra={
              <Link type="primary" to="/">
                Quay về trang chủ
              </Link>
            }
          />
        </div>
      ),
    },
    {
      path: '/register-seller',
      element: <LayoutBase />,
      children: [
        {
          index: true,
          element: <RegisterSeller />,
        },
      ],
      errorElement: (
        <div className="w-full min-h-screen flex justify-center items-center">
          <Result
            status="404"
            title="Không tìm thấy trang"
            extra={
              <Link type="primary" to="/">
                Quay về trang chủ
              </Link>
            }
          />
        </div>
      ),
    },
    {
      path: '/reset-password',
      element: <LayoutBase />,
      children: [
        {
          index: true,
          element: <ResetPassword />,
        },
      ],
      errorElement: (
        <div className="w-full min-h-screen flex justify-center items-center">
          <Result
            status="404"
            title="Không tìm thấy trang"
            extra={
              <Link type="primary" to="/">
                Quay về trang chủ
              </Link>
            }
          />
        </div>
      ),
    },
    {
      path: '/result-payment',
      element: <ResultPayment />,

      errorElement: (
        <div className="w-full min-h-screen flex justify-center items-center">
          <Result
            status="404"
            title="Không tìm thấy trang"
            extra={
              <Link type="primary" to="/">
                Quay về trang chủ
              </Link>
            }
          />
        </div>
      ),
    },
    {
      path: '/profile',
      element: <LayoutBase />,
      children: [
        {
          index: true,
          element: (
            <ProtectedUser>
              <Profile />
            </ProtectedUser>
          ),
        },
      ],

      errorElement: (
        <div className="w-full min-h-screen flex justify-center items-center">
          <Result
            status="404"
            title="Không tìm thấy trang"
            extra={
              <Link type="primary" to="/">
                Quay về trang chủ
              </Link>
            }
          />
        </div>
      ),
    },
    {
      path: '/sellerspage',
      element: <LayoutBase />,
      children: [
        {
          index: true,
          element: (
            <ProtectedSeller>
              <SellersPage />
            </ProtectedSeller>
          ),
        },
      ],

      errorElement: null,
    },
  ])

  return (
    <>
      {showApp ? (
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />

          <ReactQueryDevtools />
        </QueryClientProvider>
      ) : (
        <LoadingPage />
      )}
      {/* Call the function to add a library */}
      {AddLibrary('https://onsite.optimonk.com/script.js?account=225773')}
    </>
  )
}

export default App
