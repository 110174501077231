// Libraries
import { useQuery } from 'react-query'

// Constants
import { QUERIES_KEYS } from 'apps/constants/queries'
import { getNotificationByShop } from 'apps/services/apis/notification.api'

// Services

export const useGetNotificationByShop = (id) => {
  const { data, isLoading } = useQuery({
    queryKey: [QUERIES_KEYS.NOTIFICATION, id],
    queryFn: () => getNotificationByShop(id),
  })

  return { notifications: data, isLoading }
}
