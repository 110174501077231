export const QUERIES_KEYS = {
  GET_PRODUCTS: 'GET_PRODUCTS',
  GET_CATEGORY: 'GET_CATEGORY',
  REGISTER_USER: 'REGISTER_USER',
  USER: 'USER',
  SUBCATEGORY: 'SUBCATEGORY',
  GET_TOPSALE_PRODUCT: 'GET_TOPSALE_PRODUCT',
  GET_ORDER: 'GET_ORDER',
  GET_PRODUCTS_BY_SHOPID: 'GET_PRODUCTS_BY_SHOPID',
  GET_SUBCATEGORIES: 'GET_SUBCATEGORIES',
  GET_SHOP_BY_USERID: 'GET_SHOP_BY_USERID',
  GET_SHOPS: 'GET_SHOPS',
  GET_ORDER_BY_SHOP: 'GET_ORDER_BY_SHOP',
  GET_ORDER_NOT_APPROVE_BY_SHOP: 'GET_ORDER_NOT_APPROVE_BY_SHOP',
  GET_ORDER_BY_ID: 'GET_ORDER_BY_ID',
  GET_CANCEL_ORDER_BY_ID: 'GET_CANCEL_ORDER_BY_ID',
  COMMENT: 'COMMENT',
  RESEND_MAIL: 'RESEND_MAIL',
  GET_USER_BY_ID: 'GET_USER_BY_ID',
  NOTIFICATION: 'NOTIFICATION',
  GET_ORDER_BY_SHOP_NOT_DELIVERY: 'GET_ORDER_BY_SHOP_NOT_DELIVERY',
}
