import { Result, Button } from 'antd'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectCurrentUser } from 'store/userSlice/userSelector'

const RoleBaseRoute = ({ children }) => {
  const currentUser = useSelector(selectCurrentUser)

  const navigate = useNavigate()

  if (currentUser?.role) {
    return <>{children}</>
  } else {
    return (
      <div className="w-full min-h-screen flex justify-center items-center">
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
          extra={
            <Button type="primary" onClick={() => navigate('/login')}>
              Login
            </Button>
          }
        />
      </div>
    )
  }
}

export default RoleBaseRoute
