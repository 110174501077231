// Libraries
import { QUERIES_KEYS } from 'apps/constants'
import { updateReadNotification } from 'apps/services/apis/notification.api'
import { useMutation, useQueryClient } from 'react-query'

export const useUpdateNotification = () => {
  const queryClient = useQueryClient()
  //navigate

  const mutation = useMutation({
    mutationFn: async (id) => updateReadNotification(id),
    onSuccess: (data) => {
      console.log(data, 'data update')
      queryClient.invalidateQueries([QUERIES_KEYS.NOTIFICATION, data.data.shopId])
    },
  })

  return {
    mutation,
    isLoading: mutation.isLoading,
  }
}
