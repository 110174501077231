import { useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import { Badge } from 'antd'
import { Link } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import classnames from 'classnames'
import socketIOClient, { io } from 'socket.io-client'
import { BellOutlined } from '@ant-design/icons'

import { useGetNotificationByShop } from 'apps/queries/notification/useGetNotificationByShop'
import { useUpdateNotification } from 'apps/queries/notification/useUpdateNotification'
import { baseUrl } from 'apps/services/apis/https'

import { CirclePoint, StyledPopover } from '../styled'

import { QUERIES_KEYS } from 'apps/constants'

export const Notification = ({ shopId }) => {
  const { notifications } = useGetNotificationByShop(shopId)
  const { mutation, isLoading } = useUpdateNotification()

  const handleReadNotification = (id) => {
    if (id) {
      mutation.mutate(id)
    }
  }
  const socketRef = useRef()
  const queryClient = useQueryClient()

  // useEffect(() => {
  //   socketRef.current = socketIOClient.connect(baseUrl)
  // }, [])

  useEffect(() => {
    const socket_id = io(baseUrl, {
      transports: ['websocket'],
      reconnectionDelayMax: 10000,
    })

    if (shopId) {
      socket_id?.on('new-order', (order) => {
        if (order.shopId === shopId) {
          queryClient.invalidateQueries([QUERIES_KEYS.NOTIFICATION, order.shopId])

          toast.success('Bạn có đơn hàng mới !!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
    }

    return () => {
      socket_id.off('new-order')
    }
  }, [queryClient, shopId])

  return (
    <div className="text-md  mr-7">
      <StyledPopover
        content={
          <div className="flex flex-col gap-1 max-h-[300px] overflow-y-auto ">
            {notifications?.map((noti) => {
              return (
                <Link to={'/sellerspage'}>
                  <div
                    key={noti?._id}
                    className={classnames(
                      'flex gap-2 items-center p-2 rounded-sm hover:opacity-75 hover:cursor-pointer',
                      {
                        'bg-slate-400': !noti?.isRead,
                      },
                    )}
                    onClick={() => handleReadNotification(noti?._id)}
                  >
                    <span>{noti?.name}</span>
                    {!noti.isRead && <CirclePoint />}
                  </div>
                </Link>
              )
            })}
          </div>
        }
        trigger="click"
      >
        <Badge
          count={notifications?.filter((noti) => !noti?.isRead)?.length || 0}
          size="default"
        >
          <BellOutlined size={16} />
        </Badge>
      </StyledPopover>
    </div>
  )
}
