import { Popover } from 'antd'
import styled from 'styled-components'

export const SearchStyle = styled.div`
  font-size: 18px;
  padding: 0 4px;
  margin-top: -5px;
  cursor: pointer;
`

export const CirclePoint = styled.div`
  clip-path: circle(50%);
  width: 4px;
  height: 4px;
  background-color: #aaaaaa;
`

export const StyledPopover = styled(Popover)`
  .ant-popover-inner {
    padding: 0 !important;
  }
`
