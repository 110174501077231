import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  promotion: {},
}

export const promotionSlice = createSlice({
  name: 'promotion',
  initialState,
  reducers: {
    setPromotion: (state, action) => {
      state.promotion = action.payload
    },

    clearPromotion: (state) => {
      state.promotion = {}
    },
  },
})

export const { setPromotion, clearPromotion } = promotionSlice.actions

export default promotionSlice.reducer
